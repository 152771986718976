<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      agents: [],
      accounts: [],
      purchaseOrder: {},
      agent: { search: null },
      selectedAccounts: null,
      levelThreeAccountId: null,
      levelThreeAccounts: [],
      branchItem: {
        isNew: 1,
        item: {},
        code: null,
        account: null,
        Adaccount: null,
      },
      addExpense: [],
    };
  },
  methods: {
    selectedAccount(search) {
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search,
          type: "Expense",
          level: 3,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
          this.search = "";
        });
    },
    editPo() {
      this.http
        .post("purchases/edit-order", {
          expenses: this.addExpense,
          id: this.purchaseOrder?.id,
        })
        .then((res) => {
          if (res.status) {
            this.$router.push("/payments_invoices");
          }
        });
    },
    deleteOption(index) {
      this.addExpense.splice(index, 1);
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
    filteredAgents() {
      return this.agents.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.http
      .post("purchases/get-order", { id: this.$route.params.id })
      .then((res) => {
        if (res.status) {
          console.log(res.data);
          this.purchaseOrder = res.data;
          res.data?.purchase_order_details.forEach((order) => {
            this.addExpense.push({
              search: `${order?.level_three_chart_of_account?.code}-${order?.level_three_chart_of_account?.name_en}`,
              unit_price: order?.unit_price,
              qty: order?.qty,
            });
          });
        }
      });
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/payments_invoices`">
            <h4 class="m-0">{{ $t("payments.title") }}</h4>
          </router-link>
          <div class="mx-2">\</div>
          <div># {{ purchaseOrder?.id }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6"></div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              ></div>
            </div>
            <div>
              <div class="purchase-container">
                <div class="row mb-2">
                  <div class="col-10">
                    <label for="asset_id">{{
                      $t("purchases.chose_items")
                    }}</label>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      @click="addExpense.push({})"
                      class="btn"
                    >
                      <span
                        class="bx bxs-plus-circle fa-2x"
                        style="color: #2a3042 !important"
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="mt-3">
                  <div v-for="(expense, index) in addExpense" :key="expense">
                    <div class="row mb-2">
                      <div class="col">
                        <AutoComplete
                          @keyup="selectedAccount(expense.search)"
                          v-model="expense.search"
                          :suggestions="filteredAccounts"
                          @complete="expense.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                      </div>
                      <div class="col d-flex">
                        <input
                          required
                          type="number"
                          style="margin-inline-end: 4px; display: inline"
                          v-model="expense.qty"
                          class="form-control"
                          :placeholder="$t('emps.qty')"
                        />
                        <input
                          required
                          type="number"
                          style="display: inline"
                          v-model="expense.unit_price"
                          class="form-control"
                          :placeholder="$t('emps.unit_price')"
                        />
                      </div>
                      <div class="col">
                        <button
                          class="btn btn-sm btn-danger"
                          @click="deleteOption(index)"
                          :disabled="addExpense.length == 1"
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="purchases-footer float-end"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <router-link to="/payments_invoices">
                <button
                  class="btn btn-secondary"
                  style="margin-inline-end: 4px"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </router-link>
              <button class="btn btn-primary" @click="editPo">
                {{ $t("payments.edit_order") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
.purchases-footer {
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
